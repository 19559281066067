import React from "react";
import {Button, TextInput, Icon} from 'react-materialize'

const Subscribe = () => (
    <div className='Footer__subscribe'>
        <TextInput
            icon={<Icon className='Footer__subscribe__icon'>email</Icon>}
            type='email'
            label='Your email'
        />
        <Button type='submit' waves='light' className='Footer__subscribe__button'>
            Submit
            <Icon className='Footer__subscribe__icon' right>send</Icon>
        </Button>
    </div>
);

export default Subscribe
