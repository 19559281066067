import React from 'react'

const Subscribe = () => (
    <form className="Header__subscribe">
        <input type="text" placeholder="you@example.com" className="Header__subscribe-input"/>
        <button type="submit" className="Header__subscribe-button">
            Subscribe
        </button>
    </form>
);

export default Subscribe
