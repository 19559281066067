import React from 'react'
import {Autocomplete} from "react-materialize";

const Search = () => (
    <Autocomplete
        className='Header__search'
        options={{
            data: {
                "Gus Fring": null,
                "Saul Goodman": null,
                "Tuco Salamanca": 'https://placehold.it/250x250'
            }
        }}
        placeholder="Search for anything"
    />
);

export default Search
