import './Logo.scss'
import React from 'react'
import {Link} from "gatsby";
import logo from "../../images/gophertuts.svg";

const Logo = props => (
    <span className={`Logo ${props.className}`}>
        <Link to="/">
            <img src={logo} alt="logo"/>
        </Link>
    </span>
);

export default Logo
