import React from "react";
import {Link} from "gatsby";
import Logo from "../Logo";

const MoreLinks = () => (
    <div>
        <div className='Footer__more-links__home'><Logo/></div>
        <ul className='Footer__more-links'>
            <Link to='/about' className='Footer__more-links__element'>About</Link>
            <Link to='/blog' className='Footer__more-links__element'>Blog</Link>
            <Link to='/tutorials' className='Footer__more-links__element'>Tutorials</Link>
            <Link to='/courses' className='Footer__more-links__element'>Courses</Link>
            <Link to='/community' className='Footer__more-links__element'>Community</Link>
        </ul>
    </div>
);

export default MoreLinks
