import React from "react";

const Contact = () => (
    <div className='Footer__contact'>
        <h5 className='white-text Footer__contact__title'>
            Contact
        </h5>
        <div className='grey-text text-lighten-4'>
            <p className='Footer__contact__paragraph'>
                <i className='contact__paragraph__label'>Country</i>: Republic of Moldova
            </p>
            <p className='Footer__contact__paragraph'>
                <i className='contact__paragraph__label'>City</i>: Chișinău
            </p>
            <p className='Footer__contact__paragraph'>
                <i className='contact__paragraph__label'>Email: </i>
                <a className='Footer__contact__cta' href='mailto:steevehook@gmail.com'>steevehook@gmail.com</a>
            </p>
            <p className='Footer__contact__paragraph'>
                <i className='contact__paragraph__label'>Phone number: </i>
                <a className='Footer__contact__cta' href='tel:+37369723304'>+373 69 723 304</a>
            </p>
        </div>
    </div>
);

export default Contact
