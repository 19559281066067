import './Footer.scss'
import React from 'react'
import {Footer as MaterialFooter} from 'react-materialize'
import Contact from "./Contact";
import Subscribe from "./Subscribe";
import Links from "./Links";
import MoreLinks from "./MoreLinks";

const year = new Date().getFullYear();
const Footer = () => (
    <MaterialFooter
        copyrights={`© ${year} GopherTuts`}
        links={<Links/>}
        moreLinks={<MoreLinks/>}
        className="Footer"
    >
        <Contact/>
        <Subscribe/>
    </MaterialFooter>
);

export default Footer
